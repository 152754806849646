import { Text } from "@zapier/design-system/cjs/components/display/Text";
import React from "react";

import styles from "@/styles/ErrorPageContent.module.css";

const ErrorPageContent = ({
  title,
  detail,
}: {
  title: string;
  detail?: string;
}) => {
  return (
    <div className={styles.container}>
      <Text
        tag="h2"
        type="pageHeader7"
      >
        {title}
      </Text>
      {detail && (
        <Text
          tag="p"
          type="paragraph3"
        >
          {detail}
        </Text>
      )}
    </div>
  );
};

export default ErrorPageContent;
